exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-coaching-tsx": () => import("./../../../src/pages/coaching.tsx" /* webpackChunkName: "component---src-pages-coaching-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-informational-post-tsx": () => import("./../../../src/templates/informational-post.tsx" /* webpackChunkName: "component---src-templates-informational-post-tsx" */),
  "component---src-templates-transactional-post-tsx": () => import("./../../../src/templates/transactional-post.tsx" /* webpackChunkName: "component---src-templates-transactional-post-tsx" */)
}

